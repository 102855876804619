import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Contact from "../components/Contact";

const BackgroundSection = ({ className, heroImg, themeImg }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "jospers-bg-redux.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        theme1: file(relativePath: { eq: "footer-bg-redux.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 600) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      const themeImgData = data.theme1.childImageSharp.fluid;

      return (
        <>
          {heroImg && !themeImg && (
            <BackgroundImage
              Tag="section"
              className={className}
              fluid={imageData}
              backgroundColor={`#040e18`}
            >
              <div className="   bg-gradient-to-bl from-black  min-h-screen  flex  ">
                <div className="w-full md:max-w-6xl  text-center m-auto flex flex-col  md:p-4 md:pt-20 ">
                  <h1 className=" mb-4 text-3xl md:text-6xl  leading-tight w-full">
                    Jospers Vin & Grill
                  </h1>

                  <p className="md:max-w-2xl   m-auto font-hairline  md:text-xl  w-full px-5 md:px-0 ">
                    Jospers Vin & Grill är ett nytt spännande och unikt koncept
                    som bygger på träkollsgrill som har revolutionerat marknaden
                    i Spanien. <br></br>Med denna grill ger Jospers den bästa
                    smaken av grillat på hela menyn av högsta kvalitet.
                  </p>

                  <div className="block mt-12 w-full">
                    <button
                      data-hash="3a9fb1e12be52a65786d31368ada53e4"
                      className="waiteraid-widget m-1 font-light tracking-wide text-white border-2 px-6 py-4 text-lg hover:text-black hover:bg-white"
                    >
                      Boka Bord
                    </button>{" "}
                  </div>
                </div>
              </div>
            </BackgroundImage>
          )}

          {themeImg && (
            <BackgroundImage
              Tag="section"
              className={className}
              fluid={themeImgData}
              backgroundColor={`#040e18`}
            >
              <div className="   bg-gradient-to-bl from-black  min-h-screen  flex  ">
                <div className="w-full md:max-w-6xl  text-center m-auto flex flex-col p-4 pt-20 ">
                  <Contact></Contact>
                </div>
              </div>
            </BackgroundImage>
          )}
        </>
      );
    }}
  />
);
BackgroundSection.propTypes = {
  className: PropTypes.array,
  heroImg: PropTypes.boolean,
  themeImg: PropTypes.boolean,
};

function index() {
  return (
    <Layout>
      <SEO keywords={[`Jospers`, `Restaurang`, `Malmo`]} title="Home" />
      <BackgroundSection
        heroImg={true}
        className="hero  min-h-screen  bg-fixed text-white -my-24 "
      ></BackgroundSection>
      <section
        id="om"
        className="bg-white  text-center  py-24 my-20 max-w-full m-auto"
      >
        <h2 className="inline-block md:mb-2 text-2xl leading-tight md:text-4xl  font-bold ">
          Om Jospers{" "}
        </h2>
        <div className="container  mx-auto text-left">
          <div className="flex flex-wrap p-6 m-auto font-hairline md:text-lg md:max-w-3xl text-center md:text-left">
            <p className=" pb-4  ">
              Jospers Vin & Grill är ett spännande nytt unikt koncept som bygger
              på en ny framtagen träkolsgrill som har revolutionerat marknaden i
              Spanien. Med denna grill levererar Jospers den bästa smaken av
              grillat från hela meny´n av högsta kvalitet. Jospers grillar allt
              från sin meny idag från förrätter, varmrätter och t o m desserter
              !
            </p>
            <p className=" pb-4">
              Är du en vinälskare har Jospers Vinbar vad du letar efter. Jospers
              är den första och enda vinbaren i Malmö där du kan få alla viner
              från hela världen på glas och pröva dig fram med fantastiska glas
              priser. Josper Vin & Grill ligger på Lilla Torg I Malmö som är en
              av Malmös mest besökta platser och kändaste platser pga dess stora
              uteserveringar och krogar runt om. Smakupplevelser, varm och en
              familjär miljö samtidigt en proffsig vänlig service är
              tyngdpunkten hos Jospers.
            </p>
          </div>
        </div>
      </section>
      <BackgroundSection
        themeImg={true}
        className="hero  min-h-screen  bg-fixed text-white  "
      ></BackgroundSection>
    </Layout>
  );
}

export default index;
