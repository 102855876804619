import React from "react";

function Contact() {
  return (
    <section id="contact" className="  ">
      <div className="container md:max-w-6xl m-auto bg-black bg-opacity-75   py-24 px-12  md:mb-24 text-center md:text-left">
        <div className="flex flex-wrap  sm:flex-row">
          <div className="w-full md:w-1/2 text-center text-lg mb-12">
            <h2 className="inline-block mb-3 text-white font-semibold text-4xl ">
              Besök Oss
            </h2>
            <p className="text-white   ">Adress:</p>
            <a
              className="text-orange-600 underline pb-2"
              href="mailto:bokabord@jospers.se"
            >
              Jospers Vin & Grill<br></br> Lilla torg 3 <br></br>211 34 Malmö
            </a>
            <p className="text-white   pt-3 ">Email:</p>
            <a
              className="text-orange-600  underline   pb-2"
              href="mailto:bokabord@jospers.se"
            >
              bokabord@jospers.se
            </a>
            <p className="text-white   pt-3">Telefon</p>
            <a
              className="text-orange-600 underline 
               pb-2"
              href="tel:0046406112199"
            >
              040-611 21 99
            </a>
          </div>
          <div className="w-full md:w-1/2 text-center">
            {" "}
            <h2 className="inline-block mb-3 text-white font-semibold text-4xl ">
              Oppetider
            </h2>
            <p>
              <ul className="text-lg w-full flex flex-col">
                <li className=" flex flex-col  justify-center w-full">
                  {" "}
                  <span className="w-full">Måndag - Torsdag </span>
                  <span className="text-orange-600 w-full">17.00 - 23.00</span>
                </li>
                <li className="flex flex-col justify-center w-full">
                  {" "}
                  <span className="">Fredag</span>
                  <span className="text-orange-600 w-full">11.30 - 01.00</span>
                </li>
                <li className="flex flex-col justify-center w-full">
                  {" "}
                  <span className=""> Lördag </span>
                  <span className="text-orange-600 w-full">12.00 - 01.00</span>
                </li>
                <li className="flex flex-col justify-center w-full">
                  {" "}
                  <span className=""> Sondag </span>
                  <span className="text-orange-600 w-full">Stängt</span>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
